import React from 'react';

function Hero() {
  return (
    <section className="hero">
      <br></br>
      <h1>Affordable BJJ Rashguards</h1>
      <button className="button">Shop Now</button>
    </section>
  );
}

export default Hero;
