import React from 'react';
import './Footer.css'; // If you have separate CSS, otherwise keep in App.css

function Footer() {
  return (
    <footer className="app-footer">
      <p>&copy; 2024 Fourth in the Fire. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
