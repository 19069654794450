import React from 'react';
import './Header.css'; // If you have separate CSS, otherwise keep in App.css

function Header() {
  return (
    <header className="app-header">
      <div className="logo">Fourth in the Fire</div>
      <nav>
        <ul className="nav-list">
          <li><a href="/">Home</a></li>
          <li><a href="/about">About</a></li>
          <li><a href="/shop">Shop</a></li>
          <li><a href="/contact">Contact</a></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
