import React from 'react';
import './About.css'; // If you have separate CSS, otherwise keep in App.css

function About() {
  return (
    <section className="aboutSection">
      <div className="aboutContent">
        <h2 className="aboutHeading">About Us</h2>
        <p className="aboutText">
        At Fourth in the Fire, our mission is to make high-quality Brazilian Jiu-Jitsu rashguards accessible to everyone. 
          As a 100% veteran-owned business based in Universal City, Texas, we know what it means to work hard and stay committed. 
          Founded by a Marine Corps veteran who served in Iraq (OIF) and Afghanistan (OEF), Fourth in the Fire is here to bring that
          same dedication to creating gear that meets the needs of the BJJ community without the premium price tag. Whether you're gearing up for 
          your next roll or just starting your BJJ journey, we’ve got your back—literally
        </p>
      </div>
    </section>
  );
}

export default About;



















         