import React from 'react';
import './App.css';
import Header from './Header';
import Hero from './Hero';
import About from './About';
import Footer from './Footer';

function App() {
  return (
    <div className="app-container">
      <Header />
      <main className="app-main">
        <Hero />
        <About />
      </main>
      <Footer />
    </div>
  );
}

export default App;
